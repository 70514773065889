import React from 'react';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import stripe from 'Assets/pictures/Stripe.svg';
import styles from 'Assets/styles/subscription/paymentMethod.module.scss';

const PaymentMethod = (props) => {
  const { paymentMethodInformation, handleRedirect } = props;
  return (
    <div className={styles['payment-method-container']}>
      <div className={styles['payment-method-title-text-container']}>
        <div className={styles['payment-method-title']}>
          {LPTranslate('LSS_Payment_Method_Title')}
        </div>
        <div className={styles['payment-method-text']}>
          {LPTranslate('LSS_Payment_Method_Description')}
        </div>
      </div>
      <div className={styles['payment-method-content']}>
        <div className={styles['payment-method-icon-billing-container']}>
          <div className={styles['payment-method-icon']}>
            <img src={stripe} className={styles['payment-img']} alt="payment" />
          </div>
          <div className={styles['payment-method-billing']}>
            <div className={styles['payment-method-billing-title-text']}>
              <div className={styles['payment-method-billing-title']}>
                {`${paymentMethodInformation.card_brand} ending in 
                ${paymentMethodInformation.card_last4}`}
              </div>
              <div className={styles['payment-method-billing-text']}>
                Expiry {paymentMethodInformation.card_exp_month}/
                {paymentMethodInformation.card_exp_year}
              </div>
            </div>
            <div className={styles['payment-method-billing-email']}>
              <EmailOutlinedIcon
                className={styles['payment-mail-icon']}
                fontSize="small"
              />
              mailto:billing@anwalt-buchen.de
            </div>
          </div>
        </div>
        <div
          data-cy="method-action-button"
          className={styles['payment-method-action-container']}
          onClick={handleRedirect}
        >
          {LPTranslate('LSS_Payment_Method_Action_Button')}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
