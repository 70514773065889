import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import LawyerNotificationMockup from 'Components/help/LawyerNotificationMockup';
import AboutUsAchievement from 'Components/help/AboutUsAchievement';
import FAQExpandableSection from 'Components/help/FAQExpandableSection';
// import RatingStars from 'Components/RatingStars/RatingStars';
import SubscriptionModalCard from 'Components/Subscription/SubscriptionModalCard';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { GET_PRICES } from 'Services/Queries/stripe';
import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';

import MacBookMockUp from 'Assets/pictures/macBookMockUp.png';
// import reviewImage from 'Assets/pictures/MironaPuehler.jpg';
import lightIcon from 'Assets/pictures/lightIcon.svg';
import pieChartIcon from 'Assets/pictures/PieChartIcon.svg';
import phoneIcon from 'Assets/pictures/PhoneIcon.svg';
import friendIcon from 'Assets/pictures/friendIcon.svg';
import avatarImage1 from 'Assets/pictures/AvatarImage1.png';
import avatarImage2 from 'Assets/pictures/AvatarImage2.png';
import avatarImage3 from 'Assets/pictures/AvatarImage3.png';
import HomePageLawyerImage1 from 'Assets/pictures/HomePageLawyerImage1.jpg';
import LawyerProfileScreenshot from 'Assets/pictures/lawyerProfileScreenshot2.png';
import HomePageLawyerIntro720p from 'Assets/videos/Effektives-Kanzlei-Marketing720p.mp4';
import HomePageLawyerIntro1080p from 'Assets/videos/Effektives-Kanzlei-Marketing1080p.mp4';
import HomepageIntroThumbnail from 'Assets/videos/LawyerThumbnail.jpg';

import 'Assets/styles/lawyer/HomePageLawyer.scss';
import styles from 'Assets/styles/price/PriceCards.module.scss';

const advantagesDummyData = [
  {
    icon: pieChartIcon,
    title: LPTranslate('LLP_Info_2_Box_1_Title'),
    content: LPTranslate('LLP_Info_2_Box_1_Description'),
  },
  {
    icon: lightIcon,
    title: LPTranslate('LLP_Info_2_Box_2_Title'),
    content: LPTranslate('LLP_Info_2_Box_2_Description'),
  },
  {
    icon: phoneIcon,
    title: LPTranslate('LLP_Info_2_Box_3_Title'),
    content: LPTranslate('LLP_Info_2_Box_3_Description'),
  },
  {
    icon: friendIcon,
    title: LPTranslate('LLP_Info_2_Box_4_Title'),
    content: LPTranslate('LLP_Info_2_Box_4_Description'),
  },
];

const Achievemnts = [
  {
    title: '-',
    subtitle: LPTranslate('AU_Info_1_Box_1_Title'),
    description: LPTranslate('AU_Info_1_Box_1_Description'),
  },
  {
    title: '-',
    subtitle: LPTranslate('AU_Info_1_Box_2_Title'),
    description: LPTranslate('AU_Info_1_Box_2_Description'),
  },
  {
    title: '-',
    subtitle: LPTranslate('AU_Info_1_Box_3_Title'),
    description: LPTranslate('AU_Info_1_Box_3_Description'),
  },
  {
    title: '-',
    subtitle: LPTranslate('AU_Info_1_Box_4_Title'),
    description: LPTranslate('AU_Info_1_Box_4_Description'),
  },
];

let faqsDummyData = [
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_1'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_1'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_2'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_3'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_3'),
  },
  // {
  //   question: LPTranslate('Support_FAQ_Lawyer_Question_4'),
  //   answer: LPTranslate('Support_FAQ_Lawyer_Answer_4'),
  // },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_5'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_5'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_6'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_6'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_7'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_7'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_8'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_8_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_8_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_9'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_9'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_10'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_10_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_10_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_11'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_11_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_11_2'),
  },
];

const HomePageLawyer = () => {
  const navigate = useNavigate();
  const yearly = subscriptionPageTextVariables.yearly;
  const monthly = subscriptionPageTextVariables.monthly;
  const percentage = subscriptionPageTextVariables.persentage;

  const [recurringInterval, setRecurringInterval] = useState('year');

  const processInformation = {
    schedule: {
      type: '',
      recurringInterval: '',
    },
  };

  const [DbPrices, setDbPrices] = useState([]);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const pricesQuery = useQuery(GET_PRICES);

  useEffect(() => {
    if (pricesQuery.data) {
      setDbPrices(pricesQuery.data.prices);
    }
  }, [pricesQuery.data]);

  const [fullScreen, setFullScreen] = React.useState(false);
  const [videoTime, setVideoTime] = React.useState(0);
  const [isReady, setIsReady] = React.useState(true);

  const playerRef = React.useRef(null);

  React.useEffect(() => {
    const handleFullScreenChange = () => {
      try {
        if (document.fullscreenElement) {
          setFullScreen(true);
          setIsReady(true);
        } else {
          setFullScreen(false);
          setIsReady(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleRecurringInterval = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'month':
        const yearly = document.getElementById(
          'lawp-subscription-process-modal-year-button'
        );
        const badge = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (yearly.classList.contains(styles['horizontal-tabs-year-active'])) {
          yearly.classList.remove(styles['horizontal-tabs-year-active']);
          badge.classList.remove(styles['horizontal-tabs-year-badge-active']);
        }
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-month-active']
          )
        ) {
          e.currentTarget.classList.add(styles['horizontal-tabs-month-active']);
          setRecurringInterval('month');
        }
        break;
      case 'year':
        const monthly = document.getElementById(
          'lawp-subscription-process-modal-month-button'
        );
        const badge2 = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-year-active']
          )
        ) {
          if (
            monthly.classList.contains(styles['horizontal-tabs-month-active'])
          ) {
            monthly.classList.remove(styles['horizontal-tabs-month-active']);
          }
          e.currentTarget.classList.add(styles['horizontal-tabs-year-active']);

          badge2.classList.add(styles['horizontal-tabs-year-badge-active']);
          setRecurringInterval('year');
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="lawp-home-page-lawyer-container">
      <MetaDecorator
        title={LPTranslate('LLP_Info_1_Meta_Title_1')}
        description={LPTranslate('LLP_Info_1_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/anwaelte`}
      />

      <div className="all-in-one-container">
        <div className="left">
          <div className="container-tag">
            <div className="article-type-tag">
              <h4 className="article-new-text">
                {LPTranslate('LLP_Info_1_Badge_Title')}
              </h4>
            </div>
            <h4 className="article-read-time">
              {LPTranslate('LLP_Info_1_Badge_Description')}
            </h4>
          </div>

          <div>
            <h1 className="title">{LPTranslate('LLP_Info_1_Title_1')}</h1>

            <h2 className="description">
              {LPTranslate('LLP_Info_1_Description')}
            </h2>
          </div>

          <div className="buttons-container">
            <Button
              className="sign-up"
              data-cy="register"
              onClick={() =>
                navigate('/registerLawyer', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                })
              }
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
                style={{ color: 'white' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('Button_Sign_Up')}
              </a>
            </Button>
          </div>
        </div>

        <div className="right">
          <img
            src={MacBookMockUp}
            alt="IphoneMockupImage"
            className="mock-up"
          />

          {
            <div className="mock-up-image">
              <ReactPlayer
                ref={playerRef}
                data-cy="video"
                url={
                  fullScreen
                    ? HomePageLawyerIntro1080p
                    : HomePageLawyerIntro720p
                }
                light={HomepageIntroThumbnail}
                progressInterval={100}
                onProgress={() => {
                  setVideoTime(playerRef.current.getCurrentTime());
                }}
                onReady={() => {
                  if (isReady) {
                    const timeToStart = videoTime;
                    playerRef.current.seekTo(timeToStart, 'seconds');
                    setIsReady(false);
                  }
                }}
                width={'100%'}
                height={'100%'}
                controls={true}
                muted={false}
                playing={true}
              />
            </div>
          }
        </div>
      </div>

      {/* <div className="review-section">
        <img src={reviewImage} alt="placeholder" className="review-image" />

        <div className="content">
          <RatingStars
            value={5}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />

          <h2 className="review-text">
            “anwalt-buchen.de ist das neue Kanzlei-Marketing. Mit der
            Online-Terminvereinbarung gewinne ich gezielt neue Mandanten in
            meinem Fachbereich der Betrieblichen Altersversorgung.”
          </h2>
          <h3 className="name">Dr. Mirona Pühler, LL.M.</h3>
          <h3 className="description">Pühler Rechtsanwälte PartG mbB</h3>
        </div>
      </div> */}

      <div className="advantages-container">
        <div className="container-info">
          <h4>{LPTranslate('LLP_Info_2_Tag')}</h4>
          <h2>{LPTranslate('LLP_Info_2_Title')}</h2>
          <h3>{LPTranslate('LLP_Info_2_Description')}</h3>
        </div>

        <div className="content">
          <div className="left">
            {advantagesDummyData.map((value, index) => {
              return (
                <div key={index} className="value">
                  <div className="image-container">
                    <img src={value.icon} alt="value1" className="image" />
                  </div>
                  <h2 className="value-title">{value.title}</h2>
                  <h3 className="value-text">{value.content}</h3>
                </div>
              );
            })}
          </div>

          <div className="right">
            <img
              src={HomePageLawyerImage1}
              alt="screenMockup"
              className="screen-mockup"
            />
          </div>
        </div>
      </div>

      <div className="secure-customer-container">
        <div className="purple-background-container">
          <div className="left-container">
            <div className="title-section">
              <h2 className="section-title">
                {LPTranslate('LLP_Info_3_Title')}
              </h2>

              <h3 className="section-description">
                {LPTranslate('LLP_Info_3_Description')}
              </h3>
            </div>

            <div className="buttons-container">
              <Button
                className="sign-up-button"
                data-cy="register"
                onClick={() =>
                  navigate('/registerLawyer', {
                    state: {
                      isSentFromLawyerPage: false,
                    },
                  })
                }
              >
                <a
                  href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
                  style={{ color: '#344054' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {LPTranslate('Button_Sign_Up')}
                </a>
              </Button>
            </div>
          </div>

          <div className="right-container">
            <div className="right-container-content">
              <div className="notification-group">
                <LawyerNotificationMockup
                  authorName="Olivia Rhye"
                  nameFollowup={LPTranslate('BPAC_Lawyer_1_Description_1')}
                  transparencyValue={0.95}
                  placeholderImageIndex={1}
                />
                <LawyerNotificationMockup
                  authorName="Candice Wu"
                  nameFollowup={LPTranslate('BPAC_Lawyer_2_Description_1')}
                  displayData={LPTranslate('BPAC_Lawyer_2_Description_2')}
                  transparencyValue={0.95}
                  placeholderImageIndex={2}
                />
                <LawyerNotificationMockup
                  authorName="Phoenix Baker"
                  nameFollowup={LPTranslate('BPAC_Lawyer_3_Description_1')}
                  displayData={LPTranslate('BPAC_Lawyer_3_Description_2')}
                  transparencyValue={0.75}
                  placeholderImageIndex={3}
                />
                <LawyerNotificationMockup
                  authorName="Lana Steiner Baker"
                  nameFollowup={LPTranslate('BPAC_Lawyer_4_Description_1')}
                  displayData={LPTranslate('BPAC_Lawyer_4_Description_2')}
                  transparencyValue={0.5}
                  placeholderImageIndex={4}
                />
              </div>

              <div className="mockup-container">
                <img
                  src={MacBookMockUp}
                  alt="Iphone Mockup"
                  className="iphone-mockup"
                />
                <img
                  src={LawyerProfileScreenshot}
                  alt="Iphone Mockup"
                  className="mockup-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prices-for-everyone-container">
        <div className="content">
          <div className="left">
            <h4>{LPTranslate('LLP_Info_4_Tag_Price')}</h4>
            <h2>{LPTranslate('LLP_Info_4_Title')}</h2>
            <h3>{LPTranslate('LLP_Info_4_Description')}</h3>
          </div>

          <div className={styles['subscription-overlay']}>
            <div className={styles['subscription-modal-title-component']}>
              <div
                className={
                  styles['subscription-modal-title-compoent-container']
                }
              >
                {' '}
                <div
                  className={
                    styles[
                      'subscription-modal-title-compoent-container-content'
                    ]
                  }
                >
                  {/* recurring interval select buttons */}
                  <div className={styles['header-horizontal-tabs']}>
                    {/* month button */}
                    <button
                      id="lawp-subscription-process-modal-month-button"
                      data-cy="month"
                      className={styles['horizontal-tabs-month']}
                      onClick={(e) => handleRecurringInterval(e, 'month')}
                    >
                      {monthly}
                    </button>
                    {/* yearly button */}
                    <div
                      id="lawp-subscription-process-modal-year-button"
                      data-cy="year"
                      className={`${styles['horizontal-tabs-year']} ${styles['horizontal-tabs-year-active']}`}
                      onClick={(e) => handleRecurringInterval(e, 'year')}
                    >
                      <div className={styles['horizontal-tabs-year-text']}>
                        {yearly}
                      </div>
                      <div
                        id="lawp-subscription-process-modal-year-button-badge"
                        className={`${styles['horizontal-tabs-year-badge']} ${styles['horizontal-tabs-year-badge-active']}`}
                      >
                        -{percentage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['subscription-card-section']}>
              <div className={styles['subscription-card-container']}>
                <div className={styles['subscription-card-content']}>
                  {DbPrices?.length > 0 ? (
                    <>
                      {DbPrices.map(
                        (price, index) =>
                          price.recurring_interval === recurringInterval && (
                            <SubscriptionModalCard
                              key={index}
                              subscriptionPageTextVariables={
                                subscriptionPageTextVariables
                              }
                              recurring_interval={price.recurring_interval}
                              cardTier={price.stripe_product.tier}
                              SubscriptionCardPrice={price.unit_amount / 100}
                              buttonRedirect={'/registerLawyer'}
                              role={clientInfoRedux.role}
                              priceId={price.price_id}
                              schedule={processInformation.schedule}
                            />
                          )
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="all-administration-container">
        <div className="info-container">
          <h4>{LPTranslate('LLP_Info_5_Tag')}</h4>
          <h2>{LPTranslate('LLP_Info_5_Title')}</h2>
          <h3>{LPTranslate('LLP_Info_5_Description')}</h3>
        </div>

        <div className="content-container">
          <div className="left">
            <div className="achievements-container">
              {Achievemnts.map((achievement, index) => {
                return (
                  <AboutUsAchievement
                    key={index}
                    title={achievement.title}
                    subtitle={achievement.subtitle}
                    description={achievement.description}
                  />
                );
              })}
            </div>
          </div>

          <div className="right">
            <img
              src={MacBookMockUp}
              alt="Iphone Mockup"
              className="iphone-mockup"
            />
            <img
              src={LawyerProfileScreenshot}
              alt="Iphone Mockup"
              className="mockup-image"
            />
          </div>
        </div>
      </div>

      <div className="faq-container">
        <div className="info-container">
          <h2>{LPTranslate('LLP_Info_6_Title')}</h2>
          <h3>{LPTranslate('LLP_Info_6_Description')}</h3>
        </div>

        <div className="faq">
          <FAQExpandableSection questions={faqsDummyData} />
        </div>
      </div>

      <div className="no-lawyer-yet-concainer">
        <div className="avatar-group">
          <img
            src={avatarImage1}
            alt="placeholder"
            className="avatar-side avatar"
          />
          <img
            src={avatarImage2}
            alt="placeholder"
            className="avatar-middle avatar"
          />
          <img
            src={avatarImage3}
            alt="placeholder"
            className="avatar-side avatar"
          />
        </div>
        <h3 className="text1">{LPTranslate('HFLY_Info_4_Title')}</h3>
        <h2 className="text2">{LPTranslate('HFLY_Info_4_Description')}</h2>
        <Button
          className="button-style"
          onClick={() => navigate('/kontakt')}
          data-cy="contact-us"
        >
          <a
            href={`${process.env.REACT_APP_DNS_URI}/kontakt`}
            style={{ color: 'white' }}
            onClick={(e) => e.stopPropagation()}
          >
            {LPTranslate('LLP_Info_7_Action_Button')}
          </a>
        </Button>
      </div>

      <div className="purple-footer-section">
        <div className="content">
          <h2>{LPTranslate('LLP_Info_8_Title')}</h2>
          <h3>{LPTranslate('LLP_Info_8_Description')}</h3>
          <div className="buttons-container">
            <Button
              className="demo"
              onClick={() => navigate('/login')}
              data-cy="login"
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/login`}
                style={{ color: '#344054' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('Button_Login')}
              </a>
            </Button>

            <Button
              className="sign-up"
              data-cy="register"
              onClick={() =>
                navigate('/registerLawyer', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                })
              }
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
                style={{ color: 'white' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('Button_Sign_Up')}
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageLawyer;
